<template>
	<section class="lead-cover">
		<section class="lead-msg">
			<span class="mb-2">
				Selecione em qual departamento você quer iniciar um conversa.
			</span>
			<section class="options" v-if="multiChannels.length">
				<ui-button
					v-for="(item, index) in multiChannels"
					:key="index"
					design="sm"
					class="channel-button"
					@click="start(item)"
				>
					{{ item.label }}
				</ui-button>
			</section>
			<span v-else> Não existem filas cadastradas. Não podemos prosseguir. </span>
		</section>
	</section>
</template>

<script>
	import uiButton from '@/components/ui/buttons/index.vue'

	export default {
		components: {
			uiButton,
		},

		props: {
			channels: {
				type: String,
				default: '',
			},
		},

		data() {
			return {
				multiChannels: [],
			}
		},

		methods: {
			prepareChannels() {
				if (!this.channels || this.channels == '') return

				const channels = this.channels.split('//')
				let array = []

				channels.forEach((element) => {
					const newElement = element.replace(/'/g, '"')
					array.push(JSON.parse(newElement))
				})

				this.multiChannels = array
			},

			start(channel) {
				this.$root.$emit('switch-view-stage', 'lead-data', channel)

				if(window && window.dataLayer) {
					window.dataLayer.push({'event': 'fac-channel'})
				}
			},
		},

		mounted() {
			this.prepareChannels()
		},
	}
</script>

<style lang="stylus" scoped>
	@import '../../styles.styl'
</style>
