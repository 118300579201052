import axios from 'axios'

export default {
	computed: {
		$mxAPI_Address() {
			return this.instance + 'api/v1/'
		},

		$mxAPI_LeadPayload() {
			const URL = window.location.origin
			return {
				customer: {
					name: this.form.name,
					email: this.form.email,
					mobile: this.form.mobile,
					phone: this.form.phone,
					cpf: this.form.cpf,
					cnpj: this.form.cnpj,
				},

				URI: URL,
			}
		},
	},

	mounted() {
		this.$mxAPI_CustomerConfig('resourceTracking');
	},

	methods: {
		async $mxAPI_CustomerConfig(name) {
			try {
				const { data } = await axios({
					method: 'get',
					url: this.$mxAPI_Address + 'configuration/' + name,
					headers: {
						'goscore-token': this.token,
						'goscore-token-user': this.tokenUser,
					},
				})
				if (name == 'resourceTracking') return await this.$LoadFunctionsGoogle(data)
				return data['value_long']
			} catch {
				return null
			}
		},

		async $LoadFunctionsGoogle(data) {
			if(data?.value_long && data.value_long != '') {
				let htmlCode = data.value_long
                /**
                 * remove as tags de comentário do google <!-- Google Tag Manager -->
                 */
                let scriptCode = htmlCode.replace(/<!--[\s\S]*?-->|.*Google*\n/gi, '');

                

                const srcRegex = /<script[^>]+src=["'](https?:\/\/[^"']+)["'][^>]*><\/script>/g;
                // var srcRegexHttp = /<script[^>]+src=["'](http?:\/\/[^"']+)["'][^>]*><\/script>/g;

                // Array para armazenar os atributos src
                let srcArray = [];
                let match;
                /**
                 * captura os srcs dos scripts
                 */
                while ((match = srcRegex.exec(htmlCode)) !== null) {
                    srcArray.push(match[1]);
                }
                
                srcArray.forEach(src => {
                   /**
                     * cria o script e adiciona o link para o src
                     */
                    const newScript = document.createElement('script');
                    newScript.src = src
                    /**
                     * adiciona os scripts no head da página
                    */
                    document.head.appendChild(newScript)
                })

                /**
                 * remove as tags de scripts
                 */
                const cleanCode = scriptCode.replace(/<script[^>]*>([\s\S]*?)<\/script>/gi, '$1');
                
                /**
                 * cria um novo script e adiciona o código
                 */
                
                const scriptElement = document.createElement('script');
                scriptElement.textContent = cleanCode
                
                /**
                 * adiciona os scripts no head da página
                */
               
                document.head.appendChild(scriptElement)
				this.$root.$emit('loaded-tag-manager')
			}
			else this.$root.$emit('loaded-tag-manager')
		},

		async $mxAPI_Lead(queueId) {
			try {
				const { data } = await axios({
					method: 'post',
					url: this.$mxAPI_Address + 'leads/execute/' + queueId,
					headers: {
						'goscore-token': this.token,
						'goscore-token-user': this.tokenUser,
					},
					data: { ...this.$mxAPI_LeadPayload },
				})

				return data
			} catch {
				return null
			}
		},
	},
}
