<template>
	<button 
		type="button" 
		class="fac-btn" 
		:class="[design, {loading: loading}]" 
		:disabled="disabled"
		@click="callback($event)"
	>
		<!-- @slot Utilize para inserir o texto do botão -->
		<slot />
	</button>
</template>

<script>
	/**
	 * Componente para criar botões
	 * @displayName ui-button 
	 */
	export default {
		name: 'ui-button',
		props: {
			/**
			 * Utilize para aplicar as classes do botão 
			 */
			design: {
				type: String,
				required: false,
				default: 'primary md'
			},
			/**
			 * Utilize para aplicar o estado de loading no botão
			 */
			loading: {
				type: Boolean,
				required: false,
				default: false
			},
			/**
			 * Utilize para aplicar o estado de desativado no botão
			 */
			disabled: {
				type: Boolean,
				required: false,
				default: false
			}
		},
		methods: {
			callback: function(e) {
				this.$emit('click', e);
			}
		},
	}
</script>	

<style lang="stylus" scoped>
	@import './styles.styl';
</style>



