<template>
	<div class="fac-image" :class="design">
		<img :src="image" :alt="alt" :height="h" :width="w" />
	</div>
</template>
<script>
	import userImage from '@/assets/imgs/no-avatar.png'

	export default {
		name: 'ui-image',
		props: {
			src: {
				type: String,
				required: false,
			},
			defaultSrc: {
				type: String,
				required: false,
				default: userImage,
			},
			alt: {
				type: String,
				required: false,
				default: 'image',
			},
			design: {
				type: String,
				required: false,
			},
			h: {
				type: String,
				required: false,
			},
			w: {
				type: String,
				required: false,
				default: 'auto',
			},
		},

		data() {
			return {
				image: '',
			}
		},

		mounted() {
			this.image = this.defaultSrc
			this.checkImage()
		},

		computed: {
			imageSRC() {
				return this.src
			}
		},

		methods: {
			checkImage(){
				let img = new Image()
				img.onload = () => {
					this.image = this.src
				}

				img.src = this.src
			}
		},
	}
</script>

<style lang="stylus" scoped>
	@import './styles.styl'
</style>
