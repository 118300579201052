/**
 * Recebe uma entrada e verifica se a mesma possui valor válido.
 * @param {*} value Variável que será verificada.
 * @returns Boolenao refente a verificação.
 */
export function $mx_hasValue(value) {
	if (value == null || value.length == 0 || value == undefined || value == '') return false
	return true
}

/**
 * Mixins com métodos de usos gerais, porém sem contextos limitados.
 * @method $mx_hasValue Verifica se a entrada tem um valor válido.
 * @method $mx_copyObject Retorna uma cópia do objeto.
 * @method $mx_isChanged Verifica duas entrada e retorna se há diferença.
 */
export default {
	methods: {
		$mx_hasValue,

		/**
		 * Recebe um data e devolve um data com valores alterados.
		 * @param {Object} object Recebe um objeto
		 * @param {String} string Recebe uma string // o valor a ser substituído
		 * @param {String} string Recebe uma string // o valor substituído
		 * @returns {Object} Retorna um objeto com valores alterados
		 */
		$mx_replaceDataValue(dataToReplace, valueToFind, valueToReplace) {
			const regularExpression = new RegExp(valueToFind, 'g')
			const stringData = JSON.stringify(dataToReplace).replace(
				regularExpression,
				valueToReplace,
			)

			return JSON.parse(stringData)
		},

		/**
		 * Recebe um objeto e devolve um cópia.
		 * @param {Object} object Recebe um objeto
		 * @returns {Object} Retorna uma cópia do objeto.
		 */
		$mx_copyObject(object) {
			return JSON.parse(JSON.stringify(object))
		},

		/**
		 * Recebe dois objetos e retorna se há uma diferença entre ambos.
		 * @param {Object} newObj Objeto que se espera ser mais recente.
		 * @param {Object} oldObj Objeto antigo que será a base da verificação.
		 * @returns {Boolean} Retorna um booleano referente a verificação.
		 */
		$mx_isChanged(newObj, oldObj) {
			return JSON.stringify(newObj) != JSON.stringify(oldObj)
		},

		/**
		 * Recebe uma string e verifica se o valor é igual a `'active'`.
		 * @param {String} value String que será verificada
		 * @returns {Boolean} Resultado da verificação
		 */
		$mx_checkIsActive(value) {
			return value == 'active'
		},

		/**
		 * Recebe um *booleano* e retorna o termo do contexto referente ao mesmo.
		 * @param {Boolean} value Booleano da verificação.
		 */
		$mx_activeOrInactive(value) {
			return value ? 'active' : 'inactive'
		},

		/**
		 * Recebe uma string e verifica se o valor é igual a `'and'`.
		 * @param {String} value String que será verificada
		 * @returns {Boolean} Resultado da verificação
		 */
		$mx_checkIsAnd(value) {
			return value == 'and'
		},

		/**
		 * Recebe um *booleano* e retorna o termo do contexto referente ao mesmo.
		 * @param {Boolean} value Booleano da verificação.
		 */
		$mx_andOrOr(value) {
			return value ? 'and' : 'or'
		},

		/**
		 * Recebe uma string e verifica se o valor é igual a: `'onlyOwner'`.
		 * @param {String} value String que será verificada
		 * @returns {Boolean} Resultado da verificação
		 */
		$mx_checkIsOnlyOwner(value) {
			return value == 'onlyOwner'
		},

		/**
		 * Recebe um *booleano* e retorna o termo do contexto referente ao mesmo.
		 * @param {Boolean} value Booleano da verificação.
		 */
		$mx_onlyOwnerOrDenied(value) {
			return value ? 'onlyOwner' : 'denied'
		},

		/**
		 * Recebe uma string e verifica se o valor é igual a `'yes'`.
		 * @param {String} value String que será verificada
		 * @returns {Boolean} Resultado da verificação
		 */
		$mx_checkIsYes(value) {
			return value === 'yes'
		},

		/**
		 * Recebe um *booleano* e retorna o termo do contexto referente ao mesmo.
		 * @param {Boolean} value Booleano da verificação.
		 */
		$mx_yesOrNo(value) {
			return value ? 'yes' : 'no'
		},

		/**
		 * Recebe uma string ou number e verifica se o valor é igual a `1`.
		 * @param {String | Number} value Valor que será que será verificado
		 * @returns {Boolean} Resultado da verificação
		 */
		$mx_chekIsOne(value) {
			return parseInt(value) === 1
		},

		/**
		 * Recebe um *booleano* e retorna o termo do contexto referente ao mesmo.
		 * @param {Boolean} value Booleano da verificação.
		 */
		$mx_oneOrZero(value) {
			return value ? '1' : '0'
		},
	},
}
