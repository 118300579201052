<template>
	<section class="lead-cover">
		<section class="lead-msg full-center">
			<span class="mb-2">
				Ocorreu um erro! Não foi possível prosseguir com seu atendimento.
			</span>

			<ui-button design="xl success" class="success-button" @click="start">
				Tentar novamente
			</ui-button>
		</section>
	</section>
</template>

<script>
	import uiImage from '@/components/ui/image/index.vue'
	import uiButton from '@/components/ui/buttons/index.vue'

	import helpersMixin from '@/mixins/helpers.js'

	export default {
		components: {
			uiImage,
			uiButton,
		},

		mixins: [helpersMixin],

		methods: {
			start() {
				if(window && window.dataLayer) {
					window.dataLayer.push({'event': 'fac-error'})
				}
				this.$root.$emit('switch-view-stage', 'lead-data')
			},
		},
	}
</script>

<style lang="stylus" scoped>
	@import '../../styles.styl'
</style>
