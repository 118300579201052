<template>
	<section class="lead-cover border-top" v-if="leadMessageOpen">
		<div class="lead-msg">
			{{ leadMessage }}
		</div>
	</section>
</template>

<script>
	export default {
		props: {
			messages: {
				type: String,
				default: '',
			},
		},

		data() {
			return {
				leadMessages: [],
				leadMessage: '',
				leadMessageOpen: false,
			}
		},

		methods: {
			prepareMessages() {
				if (!this.messages || this.messages == '') return

				const messages = this.messages.split('//')
				this.leadMessages = messages
			},

			setLeadMessage(lIndex = 0) {
				if (!this.leadMessages.length) return
				
				setTimeout(() => {
					let index = lIndex
					if (index > this.leadMessages.length - 1) index = 0

					this.leadMessage = this.leadMessages[index]
					this.leadMessageOpen = true

					setTimeout(() => {
						this.leadMessageOpen = false

						setTimeout(() => {
							this.setLeadMessage(index + 1)
						}, 30000)
					}, 15000)
				}, 5000)
			},
		},

		mounted() {
			this.prepareMessages()
			this.setLeadMessage()
		},
	}
</script>

<style lang="stylus" scoped>
	@import '../../styles.styl'
</style>
