import { render, staticRenderFns } from "./index.vue?vue&type=template&id=6c15e910&scoped=true&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"
function injectStyles (context) {
  
  var style0 = require("./index.vue?vue&type=style&index=0&id=6c15e910&prod&lang=stylus&scoped=true&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  "6c15e910",
  null
  ,true
)

export default component.exports