<template>
	<section class="lead-cover">
		<section class="lead-msg full-center" v-if="leadResponse">
			<span class="mb-2"> Que ótimo! O processamento foi concluído! </span>

			<ui-image
				:src="getImgCDN(leadResponse.owner.avatar)"
				design="circle border-lg"
				h="90"
				w="90"
				class="mb-2"
			/>

			<span class="mb-2">
				A partir de agora o seu atendimento será feito pelo(a)
				<strong>{{ leadResponse.owner.first_name }}</strong> através do telefone
				<strong>{{ maskValues.maskMobile(leadResponse.owner.phone) }}</strong>.
			</span>

			<ui-button design="xl success" class="success-button" @click="start">
				Iniciar Conversa no WhatsApp
			</ui-button>
		</section>
	</section>
</template>

<script>
	import uiImage from '@/components/ui/image/index.vue'
	import uiButton from '@/components/ui/buttons/index.vue'

	import helpersMixin from '@/mixins/helpers.js'

	export default {
		components: {
			uiButton,
			uiImage,
		},

		mixins: [helpersMixin],

		data() {
			return { response: null }
		},

		computed: {
			leadResponse() {
				return this.response ? this.response : null
			},

			leadMessage() {
				if (this.leadResponse.leadMessage && this.leadResponse.leadMessage != '') return `${this.leadResponse.leadMessage} / Meu nome é ${this.leadResponse.customer.name}, nos falamos pelo chat do whatsapp da página ${this.leadResponse.URI}`
				return `Meu nome é ${this.leadResponse.customer.name}, nos falamos pelo chat do whatsapp da página ${this.leadResponse.URI}`
			},

			leadFinalMobile() {
				return '55' + this.leadResponse.owner.phone
			},

			leadWhatsAppURI() {
				return `https://api.whatsapp.com/send?phone=${this.leadFinalMobile}&text=${this.leadMessage}`
			},

			/**
			 * Retorna um object com mask functions
			 * @returns {Object}
			 */
			maskValues() {
				return {
					/**
					 * @param {String} toMask
					 * @returns {String} Retorna uma string mascarada em Mobile
					 */
					maskMobile(toMask) {
						return toMask
							.replace(/\D/g, '')
							.replace(/(\d{2})(\d)/, '($1) $2')
							.replace(/(\d{4})(\d{4})/, '$1-$2')
					},
				}
			},
		},

		methods: {
			start() {
				if(window && window.dataLayer) {
					window.dataLayer.push({'event': 'fac-whatsApp'})
				}
				window.open(this.leadWhatsAppURI)
			},
		},

		mounted() {
			this.$root.$on('switch-view-stage', (tag, data) => {
				if (tag == 'lead-done') this.response = data
			})
		},

		beforeDestroy() {
			this.$root.$off('switch-view-stage')
		},
	}
</script>

<style lang="stylus" scoped>
	@import '../../styles.styl'
</style>
