<template>
	<section class="lead-cover">
		<section class="lead-msg">
			<span class="mb-2">{{ titleMessage }}</span>

			<div class="loading mb-2" v-if="loading.load">
				<ui-load-indicator :h="60" color="#00A884" />
			</div>

			<div class="mb-2" v-else>
				<section v-if="hasRequiredFields">
					<input
						v-model="form.name"
						placeholder="Qual seu nome completo?"
						class="required-field mb-1"
						v-if="requiredField('name')"
					/>
					<div class="error mb-1" v-if="dataObservations.name">
						<em class="fas fa-times-circle symbol"></em>
						<span>{{ dataObservations.name }}</span>
					</div>

					<input
						v-model="form.email"
						placeholder="Digite o seu melhor e-mail"
						class="required-field mb-1"
						v-if="requiredField('email')"
					/>
					<div class="error mb-1" v-if="dataObservations.email">
						<em class="fas fa-times-circle symbol"></em>
						<span>{{ dataObservations.email }}</span>
					</div>

					<input
						v-model="form.mobile"
						placeholder="Qual o seu whatsapp?"
						class="required-field mb-1"
						v-mask="['(##) ####-####', '(##) #####-####']"
						v-if="requiredField('mobile')"
					/>
					<div class="error mb-1" v-if="dataObservations.mobile">
						<em class="fas fa-times-circle symbol"></em>
						<span>{{ dataObservations.mobile }}</span>
					</div>

					<input
						v-model="form.phone"
						placeholder="Qual o seu telefone?"
						class="required-field mb-1"
						v-mask="['(##) ####-####', '(##) #####-####']"
						v-if="requiredField('phone')"
					/>
					<div class="error mb-1" v-if="dataObservations.phone">
						<em class="fas fa-times-circle symbol"></em>
						<span>{{ dataObservations.phone }}</span>
					</div>

					<input
						v-model="form.cpf"
						placeholder="Qual o seu CPF?"
						class="required-field mb-1"
						v-mask="['###.###.###-##']"
						v-if="requiredField('cpf') && true"
					/>
					<div class="error mb-1" v-if="dataObservations.cpf">
						<em class="fas fa-times-circle symbol"></em>
						<span>{{ dataObservations.cpf }}</span>
					</div>

					<input
						v-model="form.cnpj"
						placeholder="Qual o seu CNPJ?"
						class="required-field mb-1"
						v-mask="['##.###.###/####-##']"
						v-if="requiredField('cnpj') && false"
					/>
					<div class="error mb-1" v-if="dataObservations.cnpj">
						<em class="fas fa-times-circle symbol"></em>
						<span>{{ dataObservations.cnpj }}</span>
					</div>

					<textarea
						v-model="form.message"
						placeholder="Digite sua mensagem..."
						cols="30"
						rows="3"
						class="required-field"
					/>
					<div class="error mb-1" v-if="dataObservations.message">
						<em class="fas fa-times-circle symbol"></em>
						<span>{{ dataObservations.message }}</span>
					</div>
				</section>

				<span v-else>Não existem campos obrigatórios cadastrados no sistema.</span>
			</div>

			<ui-button
				v-if="!loading.load"
				design="xl success"
				class="success-button"
				:disabled="cantSubmit"
				@click="submit"
			>
				Avançar
			</ui-button>
		</section>
	</section>
</template>

<script>
	import uiButton from '@/components/ui/buttons/index.vue'
	import uiLoadIndicator from '@/components/ui/load-indicator/index.vue'

	import { mask } from 'vue-the-mask'

	import utilMixin from '@/mixins/util.js'
	import helpersMixin from '@/mixins/helpers.js'
	import apiMixin from '@/mixins/api.js'

	const defaultForm = () => ({
		name: '',
		email: '',
		mobile: '',
		phone: '',
		cpf: '',
		cnpj: '',
		message: '',
	})

	const defaultOptions = () => ({
		customerConfig: null,
	})

	const defaultLoading = () => ({
		load: false,
	})

	export default {
		components: {
			uiButton,
			uiLoadIndicator,
		},

		props: {
			instance: {
				type: String,
				default: 'unknown',
			},

			token: {
				type: String,
				default: 'unknown',
			},

			tokenUser: {
				type: String,
				default: 'unknown',
			},
		},

		mixins: [utilMixin, helpersMixin, apiMixin],

		directives: {
			mask,
		},

		data() {
			return {
				form: { ...defaultForm() },
				options: { ...defaultOptions() },
				loading: { ...defaultLoading() },
				queueInfo: null,
			}
		},

		computed: {
			titleMessage() {
				return this.loading.load
					? 'Processando... Aguarde'
					: 'Preencha os campos abaixo para iniciar.'
			},

			requiredFields() {
				if (
					!this.options.customerConfig ||
					!this.options.customerConfig.required ||
					this.options.customerConfig.required.length == 0
				)
					return []

				return this.options.customerConfig.required
			},

			hasRequiredFields() {
				return this.requiredFields.length > 0 ? true : false
			},

			dataValidate() {
				const hv = (v) => this.$mx_hasValue(v)
				const { name, email, mobile, phone, cpf, cnpj, message } = this.form

				const nameValidate = (n) =>
					hv(n) ? n.length >= 3 : this.optionalValidate(n, 'name')

				const emailValidate = (e) =>
					hv(e) ? this.$mx_validateEmail(e) : this.optionalValidate(e, 'email')

				const mobileValidate = (m) =>
					hv(m) ? this.$mx_validatePhone(m) : this.optionalValidate(m, 'mobile')

				const phoneValidate = (p) =>
					hv(p) ? this.$mx_validatePhone(p) : this.optionalValidate(p, 'phone')

				const cpfValidate = (cf) =>
					hv(cf) ? this.$mx_validateCPF(cf) : this.optionalValidate(cf, 'cpf')

				const cnpjValidate = (cj) =>
					hv(cj) ? this.$mx_validateCNPJ(cj) : this.optionalValidate(cj, 'cnpj')

				const messageValidate = (m) => m.length >= 7

				return {
					name: nameValidate(name),
					email: emailValidate(email),
					mobile: mobileValidate(mobile),
					phone: phoneValidate(phone),
					cpf: cpfValidate(cpf),
					cnpj: cnpjValidate(cnpj),
					message: messageValidate(message),
				}
			},

			dataObservations() {
				const dv = this.dataValidate
				const hv = (v) => this.$mx_hasValue(v)
				const { name, email, mobile, phone, cpf, cnpj, message } = this.form

				return {
					name: !dv.name && hv(name) ? 'O nome deve ter no mínimo 3 letras.' : '',
					email: !dv.email && hv(email) ? 'O e-mail informado não é válido.' : '',
					mobile: !dv.mobile && hv(mobile) ? 'O WhatsApp informado não é válido.' : '',
					phone: !dv.phone && hv(phone) ? 'O telefone informado não é válido.' : '',
					cpf: !dv.cpf && hv(cpf) ? 'O CPF informado não é válido.' : '',
					cnpj: !dv.cnpj && hv(cnpj) ? 'O CNPJ informado não é válido.' : '',
					message: !dv.message && hv(message) ? 'A mensagem deve ter no mínimo 7 letras.' : '',
				}
			},

			cantSubmit() {
				const dv = this.dataValidate
				const allTrue = Object.values(dv).every((value) => value)
				return this.loading.load || !allTrue || !this.hasRequiredFields
			},
		},

		methods: {
			requiredField(field) {
				return this.requiredFields.includes(field)
			},

			optionalValidate(value, tag) {
				const hv = (v) => this.$mx_hasValue(v)
				const rf = (v) => this.requiredField(v)
				return (hv(value) && rf(tag)) || !rf(tag)
			},

			async submit() {
				this.loading.load = true
				if(window && window.dataLayer) {
					window.dataLayer.push({'event': 'fac-form'})
				}
				const response = await this.$mxAPI_Lead(this.queueInfo.id)

				if (!response || !response.status)
					this.$root.$emit('switch-view-stage', 'lead-error')

				if (response && ['created', 'duplicated'].includes(response.status))
					this.$root.$emit('switch-view-stage', 'lead-done', {
						...response,
						...this.$mxAPI_LeadPayload,
						leadMessage: this.form.message,
					})

				this.loading.load = false
			},

			async getCustomerConfig() {
				this.loading.load = true
				this.options.customerConfig = await this.$mxAPI_CustomerConfig('customerDuplicate')
				this.loading.load = false
			},
		},

		async mounted() {
			await this.getCustomerConfig()

			this.$root.$on('switch-view-stage', (tag, data) => {
				if (!data) return

				if (tag == 'lead-data') {
					this.queueInfo = data
				}
			})
		},

		beforeDestroy() {
			this.$root.$off('switch-view-stage')
		},
	}
</script>

<style lang="stylus" scoped>
	@import '../../styles.styl'
</style>
