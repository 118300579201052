export default {
	methods: {
		$mx_validateCPF(cpf) {
			return this.validateCpf(cpf)
		},

		$mx_validateCNPJ(cnpj) {
			return this.validateCnpj(cnpj)
		},

		$mx_validateEmail(email) {
			if (email) {
				const re =
					/^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
				return email.match(re)
			} else return false
		},

		$mx_validatePhone(phone) {
			if (phone) {
				const re = /(\(?\d{2}\)?\s)?(\d{4,5}\-\d{4})/
				return phone.match(re)
			} else return false
		},

		getImgCDN(url) {
			let cdn = 'https://cdn.appfacilita.com/'
			if (url && url.indexOf('_uploads/companies/') != 0) {
				/**
				 *se não existir _uplpads/companies/ retorna a imagem alterada como base64
				 */
				return url
			} else {
				/**
				 *se existir imagem salva retorna a image caso não exista retornará a imagem no-avatar
				 */
				return url ? cdn + url : require('@/assets/imgs/no-avatar.png')
			}
		},

		validateCpf(cpf) {
			let sum = 0
			let rest
			let nonValid = [
				'00000000000',
				'11111111111',
				'22222222222',
				'33333333333',
				'44444444444',
				'55555555555',
				'66666666666',
				'77777777777',
				'88888888888',
				'99999999999',
			]
			cpf = cpf.replace(/\D+/g, '')

			if (nonValid.includes(cpf)) return false

			for (let i = 1; i <= 9; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i)
			rest = (sum * 10) % 11

			if (rest == 10 || rest == 11) rest = 0
			if (rest != parseInt(cpf.substring(9, 10))) return false

			sum = 0
			for (let i = 1; i <= 10; i++) sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i)
			rest = (sum * 10) % 11

			if (rest == 10 || rest == 11) rest = 0
			if (rest != parseInt(cpf.substring(10, 11))) return false
			return true
		},

		validateCnpj(cnpj) {
			cnpj = cnpj.replace(/[^\d]+/g, '')
			if (cnpj == '') return false

			if (cnpj.length < 14) return null

			// Elimina CNPJs invalidos conhecidos
			if (
				cnpj == '00000000000000' ||
				cnpj == '11111111111111' ||
				cnpj == '22222222222222' ||
				cnpj == '33333333333333' ||
				cnpj == '44444444444444' ||
				cnpj == '55555555555555' ||
				cnpj == '66666666666666' ||
				cnpj == '77777777777777' ||
				cnpj == '88888888888888' ||
				cnpj == '99999999999999'
			)
				return false

			// Valida DVs
			let lengths = cnpj.length - 2
			let numercs = cnpj.substring(0, lengths)
			let digits = cnpj.substring(lengths)
			let sums = 0
			let pos = lengths - 7
			for (var i = lengths; i >= 1; i--) {
				sums += numercs.charAt(lengths - i) * pos--
				if (pos < 2) pos = 9
			}
			let results = sums % 11 < 2 ? 0 : 11 - (sums % 11)
			if (results != digits.charAt(0)) return false

			lengths = lengths + 1
			numercs = cnpj.substring(0, lengths)
			sums = 0
			pos = lengths - 7
			for (i = lengths; i >= 1; i--) {
				sums += numercs.charAt(lengths - i) * pos--
				if (pos < 2) pos = 9
			}
			results = sums % 11 < 2 ? 0 : 11 - (sums % 11)
			if (results != digits.charAt(1)) return false

			return true
		},
	},
}
