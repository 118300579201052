<template>
	<section class="lead-form" v-show="activeTagManager">
		<section class="lead-main">
			<section class="lead-head" v-show="activeViewStage.tag != 'lead-alert'">
				Conversar no WhatsApp
				<img :src="facilitaLogo" alt="Facilita Logo" />
			</section>

			<transition name="small-fade">
				<div v-show="activeViewStage.tag == 'lead-alert'">
					<lead-alert :messages="alertMessages" />
				</div>
			</transition>

			<transition name="small-fade">
				<div v-show="activeViewStage.tag == 'lead-multi-channel'">
					<lead-multi-channel :channels="multiChannels" />
				</div>
			</transition>

			<transition name="small-fade">
				<div v-show="activeViewStage.tag == 'lead-data'">
					<lead-data :instance="propInstance" :token="token" :tokenUser="tokenUser" />
				</div>
			</transition>

			<transition name="small-fade">
				<div v-show="activeViewStage.tag == 'lead-done'">
					<lead-done />
				</div>
			</transition>

			<transition name="small-fade">
				<div v-show="activeViewStage.tag == 'lead-error'">
					<lead-error />
				</div>
			</transition>
		</section>

		<img
			:src="wppLogo"
			alt="WhatsApp Logo"
			class="lead-icon"
			@click="open(setPopup)"
		/>
	</section>
</template>

<script>
	import wppLogo from '@/assets/imgs/whatsapp-logo.png'
	import facilitaLogo from '@/assets/imgs/facilita-mini-logo.png'

	import leadAlert from './components/lead-alert'
	import leadMultiChannel from './components/lead-multi-channel'
	import leadData from './components/lead-data'
	import leadDone from './components/lead-done'
	import leadError from './components/lead-error'

	const defaultViewStage = () => [
		{
			tag: 'lead-alert',
			active: true,
		},
		{
			tag: 'lead-welcome',
			active: false,
		},
		{
			tag: 'lead-multi-channel',
			active: false,
		},
		{
			tag: 'lead-data',
			active: false,
		},
		{
			tag: 'lead-done',
			active: false,
		},
		{
			tag: 'lead-error',
			active: false,
		},
	]

	export default {
		components: {
			leadAlert,
			leadData,
			leadMultiChannel,
			leadDone,
			leadError,
		},

		props: {
			devMode: {
				type: Boolean,
				default: false,
			},

			instance: {
				type: String,
				default: 'unknown',
			},

			token: {
				type: String,
				default: 'unknown',
			},

			tokenUser: {
				type: String,
				default: 'unknown',
			},

			alertMessages: {
				type: String,
				default: '',
			},

			multiChannels: {
				type: String,
				default: '',
			},
		},

		data() {
			return {
				viewStage: [...defaultViewStage()],
				setPopup: 'lead-multi-channel',
				activeTagManager: false
			}
		},

		computed: {
			wppLogo() {
				return wppLogo
			},

			facilitaLogo() {
				return facilitaLogo
			},

			propInstance() {
				if (this.devMode) return 'http://facilita.dev.appfacilita.com:49167/'
				return 'https://' + this.instance + '.api.facilitavendas.com/'
			},

			activeViewStage() {
				return this.viewStage.find((view) => view.active)
			},
		},

		methods: {
			setViewStage(tag) {
				this.setPopup = this.setPopup == 'lead-alert' ? 'lead-multi-channel' : 'lead-alert'
				this.viewStage.forEach((element) => {
					if (element.tag == tag) {
						element.active = true
						return
					}
					element.active = false
				})
			},
			
			open(tag) {
				if(window && window.dataLayer) {
					window.dataLayer.push({'event': 'fac-icon'})
				}
				this.setViewStage(tag)
			}
		},

		mounted() {
			this.$root.$on('loaded-tag-manager', () => {
				this.activeTagManager = true
			})
			this.$root.$on('switch-view-stage', (tag) => {
				this.setViewStage(tag)
			})
		},

		beforeDestroy() {
			this.$root.$off('switch-view-stage')
			this.$root.$off('loaded-tag-manager')
		},
	}
</script>

<style lang="stylus" scoped>
	@import './styles.styl'
</style>
